<template>
  <div class="container pb-5">
    <h4>ยินดีต้อนรับ! <span class="text-primary">{{ UserInfo.firstName }} {{ UserInfo.lastName }}</span></h4>
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <span>จำนวนเงิน</span>
            <div class="d-flex justify-content-between">
              <div class="text-info">
                <i class="fas fa-2x fa-dollar-sign"></i>
              </div>
              <h3
                class="mb-0"
                :class="[{'text-danger': Balance<0}, {'text-success': Balance>0}]"
              >{{Balance | amountFormat}}</h3>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <span>รอผลเดิมพัน</span>
            <div class="d-flex justify-content-between">
              <div class="text-info">
                <i class="far fa-2x fa-list-alt"></i>
              </div>
              <h3 class="mb-0 text-danger">{{ waitAmount | amountFormat(2, '0.00') }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <h5>ผลหวยล่าสุด</h5>
    <table class="table table-info table-bordered table-hover">
      <thead>
        <tr>
          <th colspan="2">หวย</th>
          <th>งวด</th>
          <th>เวลาเปิด</th>
          <th>เวลาปิด</th>
          <th>3 ตัวบน</th>
          <th>2 ตัวล่าง</th>
          <th>สถานะ</th>
        </tr>
      </thead>
      <tbody v-for="group in groups" :key="group.group._id" class="text-secondary">
        <tr>
          <td colspan="8" class="alert-warning py-1">{{group.group.groupTitle}}</td>
        </tr>
        <tr v-for="item in group.items" :key="item._id">
          <td width="70" class="p-1">
            <img :src="imageIcon(item)" style="width: 60px;">
          </td>
          <td class="px-1 py-0">{{item.market.marketTitle}}</td>
          <td class="text-center px-1 py-1">{{item.roundDate.date | dispDateTime("DD-MM-YYYY")}}</td>
          <td class="text-center px-1 py-1 small">{{item.roundDate.open | dispDateTime("DD/MM/YYYY")}}<br />{{item.roundDate.open | dispDateTime("HH:mm:ss")}}</td>
          <td class="text-center px-1 py-1 small">{{item.roundDate.close | dispDateTime("DD/MM/YYYY")}}<br />{{item.roundDate.close | dispDateTime("HH:mm:ss")}}</td>
          <td v-if="item.market.marketType === 'single'" class="text-center px-1 py-0">
            <span class="badge fullsize badge-info">{{ item | result('threeNumberTop') }}</span>
          </td>
          <td v-if="item.market.marketType === 'single'" class="text-center px-1 py-0">
            <span class="badge fullsize badge-info">{{ item | result('twoNumberBottom') }}</span>
          </td>
          <td v-if="item.market.marketType === 'lottoset'" colspan="2" class="text-center px-1 py-0">{{ item | result('fourNumber') }}</td>
          <td class="text-center px-1 py-0">
            <span class="badge" :class="item.roundStatus.badge">{{item.roundStatus.text}}</span>
          </td>
        </tr>
      </tbody>
      <tbody v-if="!groups.length">
        <tr>
          <td colspan="8" class="text-center">ไม่มีข้อมูล</td>
        </tr>
      </tbody>
    </table>

    <News />
  </div>
</template>
<script>
import ReportService from '@/services/ReportService'
import moment from '@/helpers/moment'
import _ from 'lodash'
import News from '@/views/news/components/News'

export default {
  name: 'Home',
  components: {
    News
  },
  data() {
    return {
      rounds: null,
      waitAmount: 0
    }
  },
  computed: {
    UserInfo() {
      return this.$store.state.userProfile
    },
    Balance() {
      return this.$store.state.account.balance
    },
    marketGroups() {
      return this.$store.state.marketGroups
    },
    markets() {
      return this.$store.state.Merkets
    },
    groups() {
      if(!this.rounds)
        return []

      const items = _.orderBy(this.rounds.map((item)=>{

        const roundStatus = {
          badge: 'badge-secondary',
          text: ''
        }

        if(item.status === 'Open') {
          roundStatus.badge = 'badge-info'
          roundStatus.text = 'รอออกผล'
        }else
        if(item.status === 'Close') {
          roundStatus.badge = 'badge-info'
          roundStatus.text = 'รอออกผล'
        }else
        if(item.status === 'Cancel') {
          roundStatus.badge = 'badge-danger'
          roundStatus.text = 'ยกเลิก'
        }else
        if(item.status === 'Refund') {
          roundStatus.badge = 'badge-primary'
          roundStatus.text = 'คืนเงิน'
        }else
        if(item.status === 'Complete') {
          roundStatus.badge = 'badge-primary'
          roundStatus.text = 'กำลังออกผล'
        }else
        if(item.status === 'Paid') {
          roundStatus.badge = 'badge-success'
          roundStatus.text = 'จ่ายเงินแล้ว'
        }

        item.roundStatus = roundStatus

        return item
      }), ['roundDate.close'], ['asc'])

      const groups = _.groupBy(items, (round)=>{
        return round.groupId
      })

      const rounds = []
      for(const groupId in groups) {
        rounds.push({
          group: this.marketGroups.find((g)=>{
            return g._id === groupId
          }),
          items: groups[groupId]
        })
      }

      return _.orderBy(rounds, ['group.sort'], ['asc'])
    }
  },
  methods: {
    getLastResults() {
      ReportService.getLastResults()
      .then((response)=>{
        if(response.success) {
          this.rounds = response.data
        }
      })
    },
    imageIcon(item) {
      return item?.market?.imageIcon
    },
    getWaitAmount() {
      ReportService.getWaitAmount()
      .then((response)=>{
        if(response.success) {
          this.waitAmount = response.data
        }
      })
    }
  },
  mounted() {
    this.getLastResults()
    this.getWaitAmount()
  },
  filters: {
    result(market, type) {

      if(market.status === 'Cancel') {
        return 'ยกเลิก'
      }else
      if(market.status === 'Refund') {
        return 'คืนเงิน'
      }

      if(!market?.results[type])
        return ''

      return market.results[type].join('-')
    }
  }
}
</script>
<style lang="scss" scoped>
.table {
  thead {
    tr {
      th {
        text-align: center;
      }
    }
  }
  tbody {
    background-color: #FFF;
    tr {
      td {
        vertical-align: middle;
        line-height: 1.2;

        .badge {
          font-size: 95%;
          font-weight: normal;
        }
      }
    }
  }
}
</style>
